/* eslint-disable @typescript-eslint/no-explicit-any */
import {ComponentRef, Injectable, Type} from '@angular/core';
import {BackContinueTakeOverComponent} from '@Components/templates/takeovers/back-continue-takeover/back-continue-takeover.component';
import {BackContinueAction} from '@Shared/enums/back-continue-takeover-actions';
import {BackContinueTakeoverDetails} from '@Shared/interfaces/back-continue-takeover-details';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {CreateComponentService} from '../create-component-service/create-component.service';

@Injectable({
  providedIn: 'root',
})
export class BackContinueTakeoverService {
  takeoverAction$!: Observable<BackContinueAction>;
  componentRef?: ComponentRef<BackContinueTakeOverComponent>;
  continueDisabled = new BehaviorSubject<boolean>(false);

  private takeoverAction: Subject<BackContinueAction> = new Subject<BackContinueAction>();

  constructor(private createComponentService: CreateComponentService) {
    this.takeoverAction$ = this.takeoverAction;
  }

  get contentRef(): ComponentRef<any> | undefined {
    return this.createComponentService.contentRef;
  }

  showTakeover(takeoverDetails: BackContinueTakeoverDetails, contentComponent?: Type<any>) {
    if (contentComponent) {
      this.componentRef = this.createComponentService.appendComponentToBody(
        BackContinueTakeOverComponent,
        contentComponent
      );
    } else {
      this.componentRef = this.createComponentService.appendComponentToBody(BackContinueTakeOverComponent);
    }
    this.componentRef.instance.details = takeoverDetails;
  }

  onBackClicked() {
    this.takeoverAction?.next(BackContinueAction.back);
    this.createComponentService.removeComponentFromBody(this.componentRef);
  }

  onContinueClicked() {
    this.takeoverAction?.next(BackContinueAction.continue);
    this.createComponentService.removeComponentFromBody(this.componentRef);
  }
}
