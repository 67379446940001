import {Component} from '@angular/core';
import {BackContinueTakeoverService} from '@Core/services/back-continue-takeover/back-continue-takeover.service';
import {CmaStatusType} from '@Shared/enums/cma-status-type';
import {BackContinueTakeoverDetails} from '@Shared/interfaces/back-continue-takeover-details';

@Component({
  selector: 'app-back-continue-takeover',
  templateUrl: './back-continue-takeover.component.html',
  styleUrls: ['./back-continue-takeover.component.scss'],
})
export class BackContinueTakeOverComponent {
  details!: BackContinueTakeoverDetails;
  cmaStatusRef = CmaStatusType;
  disabled = this.backContinueTakeoverService.continueDisabled;

  constructor(private backContinueTakeoverService: BackContinueTakeoverService) {}

  back(): void {
    this.backContinueTakeoverService.onBackClicked();
    this.backContinueTakeoverService.continueDisabled.next(false);
  }

  continue(): void {
    this.backContinueTakeoverService.onContinueClicked();
  }
}
