<div class="takeover__container background__image background__overlay--medium">
  <div class="takeover__message">
    <ng-container>
      <div class="takeover__heading">
        {{ details.heading }}
      </div>
      <div class="takeover__content">
        @for (element of details.bodyElements; track element) {
          <div [innerHtml]="element.outerHTML"></div>
        }
      </div>
      <ng-content></ng-content>
    </ng-container>
    <div class="takeover__buttons">
      <button
        class="takeover__back_button"
        mat-stroked-button
        color="primary"
        (click)="back()"
      >
        <mat-icon class="back">arrow_back</mat-icon>
        Back
      </button>

      <button
        class="takeover__button"
        mat-flat-button
        color="primary"
        (click)="continue()"
        [disabled]="disabled | async"
      >
        <mat-icon>arrow_forward</mat-icon>
        {{ this.details.cmaStatus === cmaStatusRef.Listed ? 'Confirm' : 'Continue' }}
      </button>
    </div>
  </div>
</div>
